@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Documan;
  src: url(assets/fonts/Documan-Bold.otf);
  font-weight: 700;
}

@font-face {
  font-family: Documan;
  src: url(assets/fonts/Documan-Medium.otf);
  font-weight: 500;
}

@font-face {
  font-family: Documan;
  src: url(assets/fonts/Documan-Regular.otf);
  font-weight: 400;
}

@layer utilities {
  .font-documan {
    font-family: Documan, sans-serif;
  }
}

body {
  font-family: Documan, sans-serif;
  font-weight: 500;
}
