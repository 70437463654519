.simplebar-scrollbar.simplebar-visible:before {
  background: #eeeeee !important;
  opacity: 1 !important;
}

.simplebar-scrollbar:before {
  background: #eeeeee !important;
  opacity: 1 !important;
  left: 0px !important;
  right: 0px !important;
  top: 0px !important;
  bottom: 0px !important;
}

.simplebar-track.simplebar-vertical {
  width: 6px !important;
}

.simplebar-track.simplebar-horizontal {
  height: 6px !important;
}
